import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationMessageService {
  public constructor(private translate: TranslateService) {}

  public getErrorMessage(validatorName: string, validatorValue?: string | boolean): string | null {
    if (ValidationService.messages[validatorName]) {
      return this.getTranslation(ValidationService.messages[validatorName], validatorValue);
    }

    // validatorValue is returned from server
    if (validatorValue !== true) {
      return validatorValue as string;
    }

    return null;
  }

  private getTranslation(message: string, validatorValue: string | boolean): string {
    if (validatorValue === true) {
      return this.translate.instant(message);
    }

    return this.translate.instant(message, validatorValue);
  }
}
